import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import readingTime from 'reading-time'

import Seo from '../components/seo'
import Layout from '../components/layout'
import Hero from '../components/hero'
import Tags from '../components/tags'
import * as styles from './blog-post.module.css'

class BlogPostTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.contentfulBlogPost')
    const previous = get(this.props, 'data.previous')
    const next = get(this.props, 'data.next')
    const plainTextDescription = post.description?.raw
      ? documentToPlainTextString(JSON.parse(post.description.raw))
      : ''
    const plainTextBody = documentToPlainTextString(JSON.parse(post.body.raw))
    const { minutes: timeToRead } = readingTime(plainTextBody)

    const options = {
      renderNode: {
        'embedded-asset-block': (node) => {
          const { gatsbyImageData } = node.data.target
          if (!gatsbyImageData) {
            // asset is not an image
            return <></>
          }
          return <GatsbyImage image={gatsbyImageData} />
        },
        [INLINES.HYPERLINK]: (node) => {
          // Only process youtube links
          if (node.data.uri.includes('youtube.com')) {
            // Extract videoId from the URL
            const match =
              /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/.exec(
                node.data.uri
              )
            const videoId = match && match[7].length === 11 ? match[7] : null
            return (
              videoId && (
                <div className="video-container">
                  <iframe
                    className="video"
                    title={`https://youtube.com/embed/${videoId}`}
                    src={`https://youtube.com/embed/${videoId}`}
                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                    frameBorder="0"
                    allowFullScreen
                  />
                </div>
              )
            )
          } else {
            <a
            href={node.data.uri}
            target="blank"
            rel="noopener noreferrer"
          >{node.data.uri}</a>
          }
        },
        // [INLINES.HYPERLINK]: (node) => {
        //   if (node.data.uri.includes('player.vimeo.com/video')) {
        //     return (
        //       <span>
        //         <iframe
        //           title="Unique Title 001"
        //           src={node.data.uri}
        //           frameBorder="0"
        //           allowFullScreen
        //         ></iframe>
        //       </span>
        //     )
        //   } else if (node.data.uri.includes('youtube.com/')) {
        //     return (
        //       <span>
        //         <iframe
        //           title="Unique Title 002"
        //           src={node.data.uri}
        //           allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
        //           frameBorder="0"
        //           allowFullScreen
        //         ></iframe>
        //       </span>
        //     )
        //   }
        // },
      },
    }

    return (
      <Layout location={this.props.location}>
        <Seo
          title={post.title}
          description={plainTextDescription}
          image={`http:${post.heroImage?.resize.src}`}
        />
        <Hero
          image={post.heroImage.gatsbyImage}
          title={post.title}
          content={post.description}
        />
        <div className={styles.container}>
          <span className={styles.meta}>
            {/* {post.author?.name} &middot;{' '} */}
            <time dateTime={post.rawDate}>{post.publishDate}</time> –{' '}
            {timeToRead} perces olvasmány
          </span>
          <div className={styles.article}>
            <div className={styles.body}>
              {post.body?.raw && renderRichText(post.body, options)}
            </div>
            <Tags tags={post.tags} />
            {(previous || next) && (
              <nav>
                <ul className={styles.articleNavigation}>
                  {previous && (
                    <li>
                      <Link to={`/blog/${previous.slug}`} rel="prev">
                        ← {previous.title}
                      </Link>
                    </li>
                  )}
                  {next && (
                    <li>
                      <Link to={`/blog/${next.slug}`} rel="next">
                        {next.title} →
                      </Link>
                    </li>
                  )}
                </ul>
              </nav>
            )}
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $slug: String!
    $previousPostSlug: String
    $nextPostSlug: String
  ) {
    contentfulBlogPost(slug: { eq: $slug }) {
      slug
      title
      author {
        name
      }
      publishDate(formatString: "YYYY. MM. DD.")
      rawDate: publishDate
      heroImage {
        gatsbyImage(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
        resize(height: 630, width: 1200) {
          src
        }
      }
      body {
         raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData
          }
        }
      }
      category {
        name
      }
      description {
        raw
      }
    }
    previous: contentfulBlogPost(slug: { eq: $previousPostSlug }) {
      slug
      title
    }
    next: contentfulBlogPost(slug: { eq: $nextPostSlug }) {
      slug
      title
    }
  }
`
