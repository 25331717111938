import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo'
import Layout from '../components/layout'
import Hero from '../components/hero'
import ArticlePreview from '../components/article-preview'
import Categories from '../components/categories'

class BlogIndex extends React.Component {
  render() {
    const posts = get(this, 'props.data.allContentfulBlogPost.nodes')
    const categories = get(this, 'props.data.allContentfulCategory.nodes')

    return (
      <Layout location={this.props.location}>
        <Seo title="Bejegyzéseim  " />
        <Hero title="Bejegyzéseim " />
        <Categories categories={categories} />

        <ArticlePreview posts={posts} />
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query BlogIndexQuery {
    allContentfulCategory(
      sort: { fields: [order], order: ASC }
      filter: { node_locale: { eq: "hu-HU" } }
    ) {
      nodes {
        id
        name
        slug
        thumbnail {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    allContentfulBlogPost(
      sort: { fields: [publishDate], order: DESC }
      filter: { node_locale: { eq: "hu-HU" } }
    ) {
      nodes {
        title
        slug
        publishDate(formatString: "YYYY. MM. DD.")
        category {
          name
        }
        heroImage {
          gatsbyImage(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 212
          )
        }
        description {
          raw
        }
      }
    }
  }
`
